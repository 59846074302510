<template>
  <v-card outlined>
    <v-toolbar flat>
      <v-toolbar-title>
        Cursus
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn
        v-if="instanceStatus !== 'En attente'"
        :href="urlToGenerateExcel"
        target="_blank"
        fab
        color="warning"
        small
        class="ml-6"
      >
        <v-icon>mdi-file-excel</v-icon>
      </v-btn>

      <import-cursus-dialog
        @import-cursus="getCursus"
        :importedCursus="cursus"
        :instanceId="instanceId"
        v-if="$store.getters.hasAdminRole && instanceStatus !== 'Terminée'"
      ></import-cursus-dialog>

      <delete-cursus-dialog
        @delete-cursus="removeCursus"
        :cursus="selectedCursus"
        :instanceId="instanceId"
        v-if="$store.getters.hasAdminRole && instanceStatus === 'En attente'"
      ></delete-cursus-dialog>
    </v-toolbar>

    <v-data-table
      v-model="selectedCursus"
      :headers="dynamicHeaders"
      :items="dynamicCursus"
      sort-by="name"
      group-by="dept_acronym"
      disable-pagination
      hide-default-footer
      :show-select="$store.getters.hasAdminRole && instanceStatus === 'En attente'"
      @click:row="goTo"
      :loading="isLoadingCursus"
    >
      <template v-slot:group.header="{ headers, group, isOpen, toggle }">
        <td
          :colspan="headers.length"
          class="dept-header"
          @click="toggle"
        >
          <div class="d-flex align-center">
            <v-btn icon>
              <v-icon v-if="!isOpen">mdi-chevron-down</v-icon>
              <v-icon v-else>mdi-chevron-up</v-icon>
            </v-btn>

            <v-icon class="mx-6">{{ getDeptIcon(group) }}</v-icon>

            <span class="font-weight-bold">{{ group }}</span>
          </div>
        </td>
      </template>

      <template v-slot:item.est_name="{ item }">
        <v-chip small label>{{ item.est_name.toUpperCase() }}</v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex';
import { getDeptIcon } from '@/mixins/methodsMixin';
import ImportCursusDialog from '@/components/cursus/ImportCursusDialog.vue';
import DeleteCursusDialog from '@/components/cursus/DeleteCursusDialog.vue';

export default {
  name: 'AdminParaCursus',

  components: {
    'import-cursus-dialog': ImportCursusDialog,
    'delete-cursus-dialog': DeleteCursusDialog,
  },

  mixins: [getDeptIcon],

  props: {
    instanceId: String,
    instanceStatus: String,
    instanceType: String,
  },

  data: () => ({
    headers: [
      { text: 'NOM', value: 'name' },
      { text: 'IMPLANTATION', value: 'est_name' },
    ],
    cursus: [],
    selectedCursus: [],
    isLoadingCursus: false,
  }),

  created() {
    this.getCursus();
  },

  computed: {
    dynamicHeaders() {
      if (this.instanceStatus === 'En attente') return this.headers;
      return [...this.headers, { text: 'INSCRIPTIONS', value: 'registration_number', align: 'right' }];
    },

    dynamicCursus() {
      const res = [];
      this.cursus.forEach((cursus) => {
        const index = res.findIndex((item) => item.name === cursus.name
        && item.dept_acronym === cursus.dept_acronym);
        if (index !== -1) res[index].est_name += `, ${cursus.est_name}`;
        else res.push({ ...cursus });
      });
      return res;
    },

    urlToGenerateExcel() {
      const token = this.$http.defaults.headers.common.Authorization.replace('Bearer ', '');
      const base = process.env.VUE_APP_AXIOS_BASE_URL;
      return `${base}admin/misc-para-excel.php?instanceId=${this.instanceId}&access=${token}`;
    },
  },

  methods: {
    ...mapMutations({ showNotification: 'SET_NOTIFICATION' }),

    removeCursus() {
      this.cursus = this.cursus
        .filter((cursus) => !this.selectedCursus
          .find((item) => item.id === cursus.id));
      this.selectedCursus = [];
    },

    goTo(row) {
      if (row.registration_number === 0) return;
      this.$router.push(`/admin/pss/${this.instanceId}/cursus/${row.id}`);
    },

    async getCursus() {
      await this.$store.dispatch('getAccessToken');
      try {
        this.isLoadingCursus = true;
        const response = await this.$http.get(`admin/cursus-get.php?instance_id=${this.instanceId}&type=PARA`);
        this.cursus = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingCursus = false;
      }
    },
  },
};
</script>

<style>

.dept-header {
  cursor: pointer;
  background-color: #e6f1fc;
}

</style>
